import { animate, query, style, transition } from '@angular/animations'

export const OverlayAnimations = {
  detailsPopup: [
    transition(':enter', [
      query('[data-overlay-animation-root]', style({ opacity: 0, transform: 'scale(0.98) translateX(100px)' })),
      query(
        '[data-overlay-animation-root]',
        animate('0.3s ease-in-out', style({ opacity: 1, transform: 'scale(1) translateX(0)' }))
      )
    ]),
    transition(':leave', [
      query('[data-overlay-animation-root]', style({ opacity: 1, transform: 'translateY(0) scale(1)' })),
      query(
        '[data-overlay-animation-root]',
        animate('0.3s ease-in-out', style({ opacity: 0, transform: 'translateY(30px) scale(0.98)' }))
      )
    ])
  ]
}
